const sanityClient = require("@sanity/client");

const client = sanityClient({
  projectId: "94ssx197",
  dataset:
    process.env.GATSBY_BUILD_ENV == "local"
      ? "staging"
      : process.env.GATSBY_BUILD_ENV,
  apiVersion: "2021-10-21",
  useCdn: false,
});

const getResources = async () => {
  const query = `
      *[_type=="resource"]{
        "id":_id,
        "slug":slug.current,
        title,
        description,
        displayWeight,
        mediaType,
        openExternal,
        externalLink,
        "pdf":pdfFile.asset->{"id":_id,originalFilename, url},
        wistiaId,
        "thumbnail": thumbnail.asset->{url}.url,
        concernTypeList[]->{concernTypeName,"id":_id},
        resourceTypeList[]->{resourceTypeName,"id":_id},
        isActive
      }
`;
  return client.fetch(query).then((_results) => _results);
};

const getResourceTypes = async () => {
  const query = `
    *[_type=="resourceType"]{
      "id": _id,
      "displayWeight" :resourceTypeDisplayWeight,
      "title": resourceTypeName
  }
`;
  return client.fetch(query).then((_results) => _results);
};

const getConcernTypes = async () => {
  const query = `
    *[_type=="concernType"]{
      "id": _id,
      "displayWeight" :concernTypeDisplayWeight,
      "title": concernTypeName

    }
`;
  return client.fetch(query).then((_results) => _results);
};

module.exports = { getResources, getResourceTypes, getConcernTypes };