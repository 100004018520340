import React, { createContext, useReducer, useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { Reducer, InitialState } from "./Reducer";
import { getResources } from "libs/services/sanity";

export const Context = createContext(InitialState);
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const value = { state, dispatch };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const Store = ({ element }) => (
  <Provider>
    <CookiesProvider>{element}</CookiesProvider>
  </Provider>
);
