export const InitialState = {
  windowScrollTop: 0,
  hamburgerMenu: false,
  isMobile: false,
  isModalOpen: false,
  hasStarted: false,
  hasStartedFlaky: false,
  hasStartedIrritated: false,
  hasStartedDry: false,
  isLoadingData: true,
  showHcpDisclaimer: false,
  sanityData: null,
  oneRoofer: null,
  oneRooferTabClick: null,
  error: {
    origin: null,
    message: null,
  },
};

export const actionTypes = {
  SET_HAMBURGER_MENU: "SET_HAMBURGER_MENU",
  SET_IS_MOBILE: "SET_IS_MOBILE",
  SET_IS_MODAL_OPEN: "SET_IS_MODAL_OPEN",
  SET_WINDOW_SCROLLTOP: "SET_WINDOW_SCROLLTOP",
  SET_ERROR: "SET_ERROR",
  SET_SHOW_HCP_DISCLAIMER: "SET_SHOW_HCP_DISCLAIMER",
  SET_IS_LOADING_DATA: "SET_IS_LOADING_DATA",
  SET_SANITY_DATA: "SET_SANITY_DATA",
  SET_HAS_STARTED: "SET_HAS_STARTED",
  SET_HAS_STARTED_FLAKY: "SET_HAS_STARTED_FLAKY",
  SET_HAS_STARTED_DRY: "SET_HAS_STARTED_DRY",
  SET_HAS_STARTED_IRRITATED: "SET_HAS_STARTED_IRRITATED",
  SET_ONE_ROOFER: "SET_ONE_ROOFER",
  SET_ONE_ROOFER_TAB_CLICK: "SET_ONE_ROOFER_TAB_CLICK",
};

export const Reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_ONE_ROOFER_TAB_CLICK:
      return {
        ...state,
        oneRooferTabClick: action.payload,
      };
    case actionTypes.SET_ONE_ROOFER:
      return {
        ...state,
        oneRoofer: {
          currentStep: action.payload.step,
          currentTab: action.payload.tab,
        },
      };
    case actionTypes.SET_SANITY_DATA:
      return {
        ...state,
        sanityData: action.payload,
      };
    case actionTypes.SET_IS_LOADING_DATA:
      return {
        ...state,
        isLoadingData: action.payload,
      };
    case actionTypes.SET_HAMBURGER_MENU:
      return {
        ...state,
        hamburgerMenu: action.payload,
      };
    case actionTypes.SET_IS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    case actionTypes.SET_HAS_STARTED_FLAKY:
      return {
        ...state,
        hasStartedFlaky: action.payload,
      };
    case actionTypes.SET_HAS_STARTED:
      return {
        ...state,
        hasStarted: action.payload,
      };

    case actionTypes.SET_HAS_STARTED_DRY:
      return {
        ...state,
        hasStartedDry: action.payload,
      };
    case actionTypes.SET_HAS_STARTED_IRRITATED:
      return {
        ...state,
        hasStartedIrritated: action.payload,
      };

    case actionTypes.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case actionTypes.SET_WINDOW_SCROLLTOP:
      return {
        ...state,
        windowScrollTop: action.payload,
      };
    case actionTypes.SET_SHOW_HCP_DISCLAIMER:
      return {
        ...state,
        showHcpDisclaimer: action.payload,
      };
    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: {
          origin: action.payload.origin,
          message: action.payload.message,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
