import { Store } from './src/components/libs/redux/Store';

export const wrapRootElement = Store;

export const shouldUpdateScroll = (prevRouterProps, { location }) => {
	window.scrollTo(0, 0);
	const body = document.getElementsByTagName('body')[0];
	body.scrollTop = 0;
	return false;
};
